<template>
  <div>
    <el-card>
      <el-row class="left">
        <el-col :span="24">
          <el-form :inline="true" class="demo-form-inline">
            <el-form-item label="用户账号:">
							<el-input v-model="account" placeholder="请输入用户账号" clearable></el-input>
						</el-form-item>
            <el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-table 
        id="logListTable"
        :data="logList"
        @select="select"
        @select-all="selectAll" 
				style="width: 100%"
				:header-cell-style="{ background: '#eef1f6', color: '#606266' }">
        <el-table-column type="selection"></el-table-column>
        <el-table-column label="管理员名称" prop="admin_name"></el-table-column>
        <el-table-column label="操作账号" prop="account"></el-table-column>
        <el-table-column label="IP地址" prop="login_ip"></el-table-column>
        <el-table-column label="登陆时间" prop="login_time"></el-table-column>
			</el-table>
      <Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
    </el-card>
  </div>
</template>

<script>
let that;
let timer = null;
import Pagination from '@/components/Pagination/Pagination.vue'
export default {
  components:{
    Pagination
  },
  data () {
    return {
      queryInfo: {
        pagenum: 1,
        pagesize: 10,
      },
      total:10,
      account:'',
      chooseList:[],
      logList:[]
    }
  },
  created() {
		if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			that = this;
			this.getLogList();
		}
	},
  methods: {
    getLogList(){
			let that = this;
			var url = 'admin/log_list';
			let params = {
				account: this.account,
				size: this.queryInfo.pagesize,
				page: this.queryInfo.pagenum,
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					that.total = res.total;
					that.logList = res.list;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
    },
    //查询
    search(){
      this.getLogList();
    },
    // 重置
    reset() {
      this.account = "";
      this.getLogList();
    },
    //页数改变
    handleSizeChange(newSize) {
      this.queryInfo.pagenum=1
      this.queryInfo.pagesize=newSize
      this.getLogList();
    },
    //页码改变
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum=newPage
      this.getLogList();
    },
    //单选
    select(selection,row){
      this.chooseList=selection
      console.log(this.chooseList);
    },
    //全选
    selectAll(selection){
      this.chooseList=selection
      console.log(this.chooseList);
    },
  },
}
</script>

<style lang="scss" scoped>
@import './Login_log.scss';
</style>